import qs from 'qs'
import dayjs from 'dayjs'
import { HOST } from 'utils/helpers'
import OgUtils from 'utils/og'
import {
  SLOVAK_COUNTRY_SLUGS,
  CZECH_COUNTRY_SLUGS,
  CATEGORY_SLUG,
  FREELANCERS,
} from 'server/constants'
import { IProfile, FreelancerType } from 'redux/interfaces/profile'
import { IStats } from 'redux/interfaces/stats'
import { SocialUrls } from '../pages/freelancers/components/SocialInputs'

export type IUrls = { [key: string]: any } & SocialUrls

export default class FreelancerUtils {
  static hasCustomColor(profile: IProfile) {
    return !!profile.backgroundColor
  }

  static isTopProfile(profile: IProfile) {
    return !!profile.addons?.topStart && profile.addons?.topCount > 0
  }

  static isRegisteredDaysAgo(profile: IProfile, days: number) {
    return dayjs().diff(dayjs(profile.signup_date), 'day') >= days
  }

  static lastTopProfileMonthAgo(profile: IProfile) {
    if (!profile.addons?.topStart) {
      return false
    }

    const topDate = dayjs(profile.addons.topStart)
    const now = dayjs()
    return now.diff(topDate, 'month') >= 1
  }

  static getLanguage(profile?: IProfile) {
    const country = profile?.country

    if (!country || country === null) {
      return null
    }

    if (CZECH_COUNTRY_SLUGS.includes(country.slug)) {
      return 'cs'
    }

    if (!SLOVAK_COUNTRY_SLUGS.includes(country.slug)) {
      return 'en'
    }

    return 'sk'
  }

  static isPremium(profile: IProfile) {
    return profile?.freelancerType === FreelancerType.PREMIUM
  }

  static isInvisible(profile: IProfile) {
    return !FreelancerUtils.isPremium(profile)
  }

  static getCategoryCount(categorySlug, stats: IStats) {
    if (!stats) {
      return null
    }

    switch (categorySlug) {
      case CATEGORY_SLUG.DEVELOPERS:
        return stats['freelancers_dev_count']?.value || FREELANCERS.DEVELOPERS
      case CATEGORY_SLUG.DESIGNERS:
        return stats['freelancers_design_count']?.value || FREELANCERS.DESIGNERS
      case CATEGORY_SLUG.CREATIVES:
        return stats['freelancers_creative_count']?.value || FREELANCERS.CREATIVES
      case CATEGORY_SLUG.MARKETERS:
        return stats['freelancers_marketing_count']?.value || FREELANCERS.MARKETERS
      case CATEGORY_SLUG.NONTECH:
        return stats['freelancers_non-tech_count']?.value || FREELANCERS.NONTECH
    }
  }

  static hasSocialLinks(urls: IUrls) {
    return Boolean(
      urls.web ||
        urls.linkedin ||
        urls.instagram ||
        urls.dribble ||
        urls.behance ||
        urls.github ||
        urls.gitlab ||
        urls.twitter ||
        urls.youtube ||
        urls.vimeo ||
        urls.tiktok ||
        urls.facebook
    )
  }

  static getDetailUrl(profile: IProfile, customQuery: any = {}) {
    if (!profile) {
      return ''
    }

    return `/freelancers/detail/${profile.shortId}${qs.stringify(
      {
        ...customQuery,
      },
      { addQueryPrefix: true }
    )}`
  }

  static getMetaImage(profile) {
    const imageId = OgUtils.getLogoIdFromUrl(profile?.picture)
    return `${HOST}/api/og-freelancer?imageId=${imageId}&category=${profile.job_category.slug}&locale=sk`
  }

  static getMetaDescription(profile) {
    if (profile) {
      return `Úroveň: ${FreelancerUtils.getSkillLevel(profile)} | Hodinovka: ${
        profile.job_salary_min
      } — ${profile.job_salary_max} €`
    }

    return ''
  }

  static getSkillLevel(profile) {
    switch (profile?.skill_level?.slug) {
      case 'junior':
        return 'Junior ⭐️'
      case 'mid':
        return 'Mid ⭐️⭐️'
      case 'senior':
        return 'Senior ⭐️⭐️⭐️'
      case 'standard':
      default:
        return ''
    }
  }

  static isFreelancerMissingSticker(freelancer) {
    if (
      freelancer.freelancerType !== 'premium' ||
      !freelancer.public ||
      freelancer.status !== 'online'
    ) {
      return false
    }

    return !freelancer.sticker
  }

  static getFreelancersExtraShareStatus(freelancer) {
    const getStatus = (status) => {
      if (freelancer?.addons?.socialShare?.[status]?.tw) {
        delete freelancer?.addons?.socialShare?.[status]?.tw
      }

      const values = Object.values(freelancer?.addons?.socialShare?.[status] ?? {})

      return values.flatMap((pendingValues) => pendingValues).length ? status : null
    }

    return getStatus('failed') ?? getStatus('pending') ?? getStatus('done')
  }

  static isFreelancerMissingExtraShare(freelancer) {
    const status = this.getFreelancersExtraShareStatus(freelancer)

    return status === 'failed' || status === 'pending'
  }

  static getFreelancerExtraShareStatusIcon(freelancer) {
    const status = this.getFreelancersExtraShareStatus(freelancer)

    return status === 'failed'
      ? '❌'
      : status === 'pending'
        ? '⏳'
        : status === 'done'
          ? '✅'
          : null
  }

  static isFreelancerMissingSocialAction(freelancer) {
    if (
      freelancer.freelancerType !== 'premium' ||
      !freelancer.public ||
      freelancer.status !== 'online'
    ) {
      return false
    }

    if (!freelancer.share) {
      return true
    }

    return !freelancer.share.fb || !freelancer.share.ig || !freelancer.share.li
  }
}
