import React, { FC } from 'react'
import Link from 'next/link'
import dayjs from 'dayjs'
import classnames from 'classnames'
import Image from 'next/image'
import { IProfile } from 'redux/interfaces/profile'
import RouterUtils from 'utils/router'
import Emoji from 'components/Emoji'
import ImageUtils from 'utils/image'
import useTranslation from 'translations/hooks/useTranslation'
import ExpandableTags from 'components/ExpandableTags'
import ArrayUtils from 'utils/array'
import FreelancerPills from './FreelancerPills'
import FreelancerFavourite from './FreelancerFavourite'

interface IProps {
  profile: IProfile
  addToQuery?: (key: string, value: string) => void
}

const Freelancer: FC<IProps> = ({ profile, addToQuery }) => {
  const { t } = useTranslation()

  const onTagClick = (slug: string) => {
    if (addToQuery) {
      return addToQuery('tags', slug)
    }

    return RouterUtils.push(`/freelancers?tags=${slug}`)
  }

  const url = `/freelancers/detail/${profile.shortId}`

  return (
    <div className="container job-container">
      <Link
        href={url}
        className={classnames('job job--level-2', {
          'job--with-logo': profile.picture,
          'job--dark-bg-variant':
            ImageUtils.getContrast(profile.backgroundColor) === 'white',
        })}
        style={
          profile.backgroundColor ? { background: profile.backgroundColor } : undefined
        }
      >
        {profile.picture && (
          <div className="job__logo-wrapper job__logo-wrapper--bigger">
            <Image
              quality={100}
              width={100}
              height={100}
              className="job__logo job__logo--rounded"
              src={`${profile.picture}_360x360`}
              alt={`${profile.name}`}
            />
          </div>
        )}
        <div className="job__content-left">
          <h2 className="job__title">
            <Emoji>{profile.name}</Emoji>
            <FreelancerPills profile={profile} addToQuery={addToQuery} />
          </h2>
          <h3 className="job__subtitle">
            <Emoji>{profile.title}</Emoji>
          </h3>

          {profile.tags && profile.tags.length > 0 && (
            <ExpandableTags
              tags={ArrayUtils.sortByValue(profile.tags, 'slug', 'sng')}
              identifier={profile._id}
              onTagClick={onTagClick}
            />
          )}
        </div>

        <div className="job__content-right-wrapper">
          <div className="job__content-right">
            <p className="job__info job__info-desktop">
              <span className="job__info--light">{t('common.salaryPerHour')}:</span>{' '}
              {profile.job_salary_min} — {profile.job_salary_max} €
            </p>
            <p className="job__info job__info-desktop">
              <span className="job__info--light">{t('filter.skillLevel')}:</span>{' '}
              <Emoji>
                {t('freelancers.skillLevel.plain.' + profile.skill_level.slug)}
              </Emoji>
            </p>

            <p className="job__info job__info-desktop">
              <span className="job__info--light">{t('common.dateSignup')}:</span>{' '}
              <Emoji>{dayjs(profile.signup_date).format('DD.MM.YYYY')}</Emoji>
            </p>

            <p className="job__info job__info-mobile">
              <Emoji>
                {t('freelancers.skillLevel.plain.' + profile.skill_level.slug)}
              </Emoji>
              {' / '}
              {profile.job_salary_min} — {profile.job_salary_max} €
            </p>
          </div>
        </div>

        <FreelancerFavourite
          profile={profile}
          className="freelancer__favourite-desktop"
        />
      </Link>
    </div>
  )
}

export default Freelancer
