const ArrayUtils = {
  generateArray: (length: number) => {
    return Array.from(Array(length).keys())
  },
  sortByValue: <T extends { [x: string]: any }>(
    data: T[],
    key: string,
    value: string | string[]
  ) => {
    const output = [...data]

    if (Array.isArray(value)) {
      output.sort((a, b) => {
        const posA = value.indexOf(a[key])
        const posB = value.indexOf(b[key])
        const rankA = posA === -1 ? Infinity : posA
        const rankB = posB === -1 ? Infinity : posB
        return rankA - rankB
      })
    } else {
      output.sort((a, b) => {
        if (a[key] === value) {
          return -1
        }
        if (b[key] === value) {
          return 1
        }
        return 0
      })
    }

    return output
  },
}

export default ArrayUtils
